import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { formatNumber } from "../../../helpers/utils";
import { IFirewall, ITestsResult } from "twillio-tests/core/TestResults";
import { withStyles } from "@material-ui/core";
import SlicedDonutChart from "./NewTests/UI/SlicedDonutChart";
import ResultRow from "./UI/ResultRow";

interface IFirewallTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  currentTheme?: string;
  classes?: any;
  config?: TestConfiguration;
  logs?: any;
}

const initialValues: IFirewall = {
  error: undefined,
  totalConnections: 0,
  successfulConnections: 0,
  failedConnections: 0,
  totalUDPConnections: 0,
  totalTCPConnections: 0,
  successfulUDPConnections: 0,
  successfulTCPConnections: 0,
  failedUDPConnections: 0,
  failedTCPConnections: 0,
  successfulConnectionsData: [],
  failedConnectionsData: [],
};

const styles = (theme: any): any => ({
  testContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "16px",
    justifyContent: "center",
    padding: "16px",
  },
  resultWrapper: {
    display: "flex",
    flex: 1,
    width: "100%",
  },
  svgContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    height: "100%",
  },
  resultContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center",
  },
  results: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  titleColor: {
    color: theme.newPalette.text.primary,
    textTransform: "capitalize",
  },
  failedDomainSection: {
    display: "flex",
    flexDirection: "column",
    fontSize: "12px",
    lineHeight: "14.4px",
    letterSpacing: "0.4px",
  },
  failedDomainText: {
    color: theme.newPalette.text.primary,
  },
  failedDomain: {
    color: theme.newPalette.error.main,
  },
});

const FirewallTest = (props: IFirewallTestProps) => {
  const { mainColor, testResult, theme, knowledgeUrl, estimateTime, testName, classes, config } = props;
  const firewall: IFirewall = testResult && testResult.firewall ? testResult.firewall : initialValues;
  const {
    error,
    meta,
    totalConnections,
    failedConnections,
    successfulUDPConnections,
    successfulTCPConnections,
    failedUDPConnections,
    failedTCPConnections,
  } = firewall;
  const defaultProps = {
    mainColor,
    color: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
    formatting: (value: number) => `${formatNumber(value)} <span>ms</span>`,
  };

  const colorTypes = {
    errorColor: config?.useNewWidgetDesign ? theme.newPalette.error.main : theme.customColors.errorColor,
    warningColor: config?.useNewWidgetDesign ? theme.newPalette.warning.main : theme.customColors.warningColor,
    successColor: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
  };

  const { t } = useTranslation(["common", "tests"]);
  const title = t("tests:firewall.title");

  const findBlockedDomain = (testResult?: ITestsResult) => {
    if (testResult) {
      if (testResult.firewall?.failedConnectionsData?.length) {
        const data = testResult.firewall.failedConnectionsData[0];
        return `${data.address}:${data.port}`;
      }
    }

    return undefined;
  };

  const blockedDomain = findBlockedDomain(testResult);

  return (
    <TestHeader
      title={title}
      tooltip={t("tests:firewall.title")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      errorsCount={meta?.errorsCount}
      testName={testName}
    >
      <div className={classes.testContainer}>
        <div className={classes.resultWrapper}>
          <div className={classes.svgContainer}>
            {totalConnections !== undefined && failedConnections !== undefined && (
              <SlicedDonutChart
                x={totalConnections}
                y={failedConnections}
                parentId="FRWL-r-connected"
                childId="FRWL-c-connected"
                themeColor={colorTypes}
                theme={theme}
                type="firewall"
              />
            )}
          </div>
          <div className={classes.resultContainer}>
            <div className={classes.results}>
              <ResultRow
                variant="with-title"
                title={t("udp")}
                value={successfulUDPConnections}
                sessionValue={failedUDPConnections}
                bitrateColor={defaultProps.color}
                color={failedUDPConnections > 0 ? colorTypes.errorColor : defaultProps.color}
                id="FRWL-r-udp"
                valueId="FRWL-c-successfulUDPConnections"
                sessionId="FRWL-c-failedUDPConnections"
                tstId="tst-frwl-udp-success"
                valueTstId="tst-frwl-udp-fail"
                type="firewall"
              />
              <ResultRow
                variant="with-title"
                title={t("tcp")}
                value={successfulTCPConnections}
                sessionValue={failedTCPConnections}
                bitrateColor={defaultProps.color}
                color={failedTCPConnections > 0 ? colorTypes.errorColor : defaultProps.color}
                id="FRWL-r-tcp"
                valueId="FRWL-c-successfulTCPConnections"
                sessionId="FRWL-c-failedTCPConnections"
                tstId="tst-frwl-tcp-success"
                valueTstId="tst-frwl-tcp-fail"
                type="firewall"
              />
            </div>
          </div>
        </div>
        {blockedDomain && (
          <div className={classes.failedDomainSection}>
            <span className={classes.failedDomainText}>Failed IP:</span>
            <span className={classes.failedDomain}>{blockedDomain}</span>
          </div>
        )}
      </div>
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(FirewallTest));
