import React from "react";
import { Typography, withStyles, withTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OpenInNewIcon } from "../../../../../assets/icons";

interface SlicedDonutChartProps {
  x: number;
  activeColor?: string;
  statusTitle?: string;
  classes?: any;
  config?: any;
  scoringResult?: any;
  theme?: any;
  hideOpenNewIcon?: boolean;
}

export const scoreStyles = (theme: any): any => ({
  scoreExplainerLink: {
    display: "flex",
    gap: "16px",
    marginBottom: "32px",
    alignItems: "center",
  },
  resultText: {
    color: theme.newPalette.text.primary,
    textAlign: "center",
  },
  btnOverride: {
    border: "none",
    padding: 0,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&:disabled": {
      backgroundColor: "transparent",
    },
    cursor: "pointer",
  },
  openNewIcon: {
    color: theme.newPalette.text.primary,

    padding: "4px",
    borderRadius: "9999px",
    "&:hover": {
      background: "rgba(36, 53, 68, 0.04)",
    },
    cursor: "pointer",
  },
});

const ScoreDonutChart = ({
                           x,
                           activeColor,
                           statusTitle,
                           classes,
                           config,
                           scoringResult,
                           theme,
                           hideOpenNewIcon
                         }: SlicedDonutChartProps) => {
  const inactiveColor = theme.newPalette.action.hover;
  const statusTextColor = theme.newPalette.text.secondary;

  const centerX = 141.5;
  const centerY = 141.5;
  const firstLineY = centerY - 12;
  const secondLineDy = 40;
  const total = 7;
  const sliceAngle = (2 * Math.PI) / total;
  const gap = 0.04;

  const slices = Array.from({ length: total }, (_, i) => {
    const inRange = x >= i && x <= i + 1;

    const startAngle = i * sliceAngle + Math.PI / 1.28;
    const startAngleX = (x - 1) * sliceAngle + Math.PI / 1.28;

    const endAngle = startAngle + sliceAngle;
    const color = i < x ? activeColor : "none";

    let endVal = endAngle;

    if (inRange) {
      endVal = sliceAngle + startAngleX;
    }

    return { startVal: startAngle, endVal, startAngle, endAngle, color, number: i.toString() };
  });

  const { t } = useTranslation(["tests"]);
  let customProjectColor;
  switch (config?.theme) {
    case "salesloft":
      customProjectColor = "#054a2e";
      break;
    case "dialpad":
      customProjectColor = "#7c52ff";
      break;
    default:
      customProjectColor = "";
  }

  return (
    <>
      {config?.scoreExplainerLink ? (
        <div className={classes.scoreExplainerLink}>
          <Typography
            variant="h2"
            className={classes.resultText}
            style={{
              color: customProjectColor,
            }}
          >
            {scoringResult ? t("tests:resultPage.scoring-result-title1") : t("tests:resultPage.scoring-result-title2")}
          </Typography>
          {!hideOpenNewIcon && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={config?.scoreExplainerLink}
              className={`${classes.btnOverride} ${classes.openNewIcon}`}
              style={{ height: 32 }}
            >
              <OpenInNewIcon />
            </a>
          )}
        </div>
      ) : (
        <Typography
          variant="h2"
          className={classes.resultText}
          style={{
            color: customProjectColor,
          }}
        >
          {t("tests:resultPage.scoring-result-title1")}
        </Typography>
      )}
      <svg width="283" height="283" viewBox="0 0 283 283" fill="none" xmlns="http://www.w3.org/2000/svg">
        {slices.map((slice, index) => (
          <DonutSlice
            key={index}
            index={index}
            center={centerY}
            radius={centerY}
            innerRadius={118}
            startAngle={slice.startAngle}
            endAngle={slice.endAngle}
            startVal={slice.startVal}
            endVal={slice.endVal}
            color={slice.color}
            inactiveColor={inactiveColor}
            gap={gap}
          />
        ))}
        <text x={centerX} y={firstLineY} textAnchor="middle" alignmentBaseline="middle" className="scoring">
          <tspan
            style={{
              fill: activeColor,
              fontWeight: 700,
              fontSize: "38px",
              lineHeight: "57px",
              letterSpacing: "-0.5px",
            }}
            id="scoring"
          >
            {x}/5
          </tspan>
        </text>
        <text x={centerX} y={centerY} dy={secondLineDy} textAnchor="middle" alignmentBaseline="middle">
          <tspan
            style={{
              fill: statusTextColor,
              fontSize: "20px",
              lineHeight: "35px",
              letterSpacing: "0.15px",
              textTransform: "capitalize",
            }}
            id="scoring-status"
          >
            {statusTitle}
          </tspan>
        </text>
      </svg>
    </>
  );
};

const createArc = (
  centerX: number,
  centerY: number,
  radius: number,
  innerRadius: number,
  startAngle: number,
  endAngle: number,
  gap: number
) => {
  const adjustedStartAngle = startAngle + gap / 2;
  const adjustedEndAngle = endAngle - gap / 2;
  const startOuter = {
    x: centerX + radius * Math.cos(adjustedStartAngle),
    y: centerY + radius * Math.sin(adjustedStartAngle),
  };
  const endOuter = {
    x: centerX + radius * Math.cos(adjustedEndAngle),
    y: centerY + radius * Math.sin(adjustedEndAngle),
  };
  const startInner = {
    x: centerX + innerRadius * Math.cos(adjustedEndAngle),
    y: centerY + innerRadius * Math.sin(adjustedEndAngle),
  };
  const endInner = {
    x: centerX + innerRadius * Math.cos(adjustedStartAngle),
    y: centerY + innerRadius * Math.sin(adjustedStartAngle),
  };

  const largeArcFlag = adjustedEndAngle - adjustedStartAngle <= Math.PI ? 0 : 1;
  const d = [
    `M ${startOuter.x} ${startOuter.y}`,
    `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endOuter.x} ${endOuter.y}`,
    `L ${startInner.x} ${startInner.y}`,
    `A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${endInner.x} ${endInner.y}`,
    "Z",
  ].join(" ");

  return d;
};

const DonutSlice = (props: any) => {
  const {
    index, startVal, endVal, center, radius, innerRadius, startAngle, endAngle, color, gap, inactiveColor
  } = props;
  const d = createArc(center, center, radius, innerRadius, startVal, endVal, gap);
  const dX = createArc(center, center, radius, innerRadius, startAngle, endAngle, gap);
  return <>
    {index < 5 && <path d={dX} fill={inactiveColor} />}
    <path d={d} fill={color} />
  </>;
};

export default withTheme()(withStyles(scoreStyles)(ScoreDonutChart));
