export const StatusCodes = [
  {
    statusCode: 10001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141715973775-Status-10001-Old-browser-version",
  },
  {
    statusCode: 10002,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141705858063-Status-10002-Data-center-selected-might-not-be-optimal",
  },
  {
    statusCode: 10200,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9978570576655-Status-10200-Low-memory",
  },
  {
    statusCode: 10300,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9890005958671-Status-10300-Connection-times-are-too-high",
  },
  {
    statusCode: 20001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141700333327-Status-20001-High-outgoing-packet-loss",
  },
  {
    statusCode: 20002,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141745750799-Status-20002-High-incoming-packet-loss",
  },
  {
    statusCode: 20003,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141705868559-Status-20003-High-RTT",
  },
  {
    statusCode: 20004,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141722577295-Status-20004-Connected-but-no-media",
  },
  {
    statusCode: 20005,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141705872655-Status-20005-Bandwidth-estimation",
  },
  {
    statusCode: 30001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141729331087-Status-30001-WiFi-access-point-is-far-away",
  },
  {
    statusCode: 30002,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141745766799-Status-30002-You-are-connected-via-WiFi",
  },
  {
    statusCode: 30003,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141700350991-Status-30003-30004-30006-You-are-connected-via-VPN",
  },
  {
    statusCode: 30004,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141700350991-Status-30003-30004-30006-You-are-connected-via-VPN",
  },
  {
    statusCode: 30005,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141745776015-Status-30005-30007-You-are-connected-via-cellular",
  },
  {
    statusCode: 30006,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141700350991-Status-30003-30004-30006-You-are-connected-via-VPN",
  },
  {
    statusCode: 30007,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141745776015-Status-30005-30007-You-are-connected-via-cellular",
  },
  {
    statusCode: 30100,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/10866351891727-Status-30100-User-changed-location-or-ISP-since-last-run",
  },
  {
    statusCode: 40001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141745777807-Status-40001-UDP-connectivity",
  },
  {
    statusCode: 50001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141729343887-Status-50001-Low-throughput",
  },
  {
    statusCode: 50100,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9890021081231-Status-50100-Possible-use-of-a-home-network",
  },
  {
    statusCode: 60001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141722605071-Status-60001-Wireless-headset",
  },
  {
    statusCode: 60002,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141705891727-Status-60002-60003-Virtual-Desktop-VDI",
  },
  {
    statusCode: 60003,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9141705891727-Status-60002-60003-Virtual-Desktop-VDI",
  },
  {
    statusCode: 60011,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9889988926095-Status-60011-Microphone-camera-has-a-generic-device-failure",
  },
  {
    statusCode: 60012,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9889978063119-Status-60012-Microphone-camera-is-in-use-by-another-application",
  },
  {
    statusCode: 60013,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9889978067471-Status-60013-User-denied-access-to-camera-microphone",
  },
  {
    statusCode: 60014,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/9889957254031-Status-60014-User-denied-access-to-camera-microphone",
  },
  {
    statusCode: 60015,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/10866330528399-Status-60015-Microphone-camera-not-supported",
  },
  {
    statusCode: 60100,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/10866351905935-Status-60100-User-switched-microphone-since-last-run",
  },
  {
    statusCode: 60101,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/10866314778639-Status-60101-User-switched-camera-since-last-run",
  },
];
