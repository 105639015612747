import React from "react";
import { connect } from "react-redux";
import Scrollbars from "react-custom-scrollbars";

import isArray from "lodash/isArray";
import startCase from "lodash/startCase";
import isNaN from "lodash/isNaN";
import isObject from "lodash/isObject";

import Modal from "@material-ui/core/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { ILog, ITestsResult, LogColors } from "twillio-tests/core/TestResults";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { GrayCloseIcon } from "../../assets/icons";

interface ILogModalProps {
  classes?: any;
  open: boolean;
  anchor?: string;
  uuid?: any;
  isQrtcSdkResult?: boolean;
  testResult?: ITestsResult;

  setOpen(): void;
}

interface IDispatch {
  theme?: string;
  logs?: ILog[];
  config?: TestConfiguration;
}

const containsHtml = (messageText: any) => /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(messageText);

const mapStateToProps = (state: any) => {
  return {
    theme: state.document.theme,
    logs: state.tests.logs,
    uuid: state.document.uuid,
    config: state.tests.config,
    isQrtcSdkResult: state.tests.testResult?.isQrtcSdkResult,
    testResult: state.tests.testResult,
  };
};

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    backgroundColor: "#fff",
    padding: "20px 16px 10px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  newPaper: {
    position: "absolute" as "absolute",
    outline: "none",
    padding: "32px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
  },
  logContainer: {
    background: theme.newPalette.cardBackground,
    height: "100%",
    padding: "16px 24px",
    borderRadius: "4px",
    boxShadow: "0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.20)",
  },
  title: {
    display: "block",
    textAlign: "center" as "center",
    fontSize: 20,
    fontWeight: 600,
    padding: "0 0 10px",
    borderBottom: "1px solid #c9cbd1",
    marginBottom: 16,
    color: theme.palette.primary.main,
  },
  title_mend: {
    fontWeight: 500,
  },
  title_amazon: {
    fontWeight: 400,
  },
  plainMessage: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontWeight: 400,
    paddingBottom: 5,
    paddingRight: 17,
    paddingLeft: 3,
    "&.red": {
      color: theme.customColors.errorColor,
    },
    "&.green": {
      color: theme.customColors.successColor,
    },
  },
  plainTextContainer: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "8px 0",
  },
  newPlainMessage: {
    "&:first-of-type": {
      "white-space": "nowrap",
    },
    color: theme.newPalette.text.primary,
    "&.red": {
      color: theme.newPalette.error.main,
    },
    "&.green": {
      color: theme.newPalette.primary.testrtc.main,
    },
  },
  plainMessage_avatour: {
    "&.green": {
      color: theme.palette.secondary.main,
    },
  },
  content: {
    height: 480,
    overflow: "auto",
    padding: "5px 70px 5px 5px",
    "& *": {
      verticalAlign: "baseline",
    },
    "& > div:last-child": {
      background: "#c9cbd1!important",
      width: "5px!important",
      "& div": {
        background: `${theme.palette.primary.main}!important`,
        width: "3px!important",
        left: 1,
        top: 1,
      },
    },
  },
  close: {
    position: "absolute" as "absolute",
    bottom: "100%",
    right: 0,
    color: "#fff",
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
    cursor: "pointer",
  },
  table: {
    width: "100%",
    "& tr": {
      "& td": {
        "& p": {
          margin: 0,
          color: theme.palette.secondary.main,
          fontSize: 14,
          fontWeight: 400,
          paddingBottom: 5,
          paddingRight: 5,
          "&.red": {
            color: theme.customColors.errorColor,
          },
          "&.green": {
            color: theme.customColors.successColor,
          },
        },
        "&:first-child": {
          width: 75,
          whiteSpace: "nowrap" as "nowrap",
        },
        "&:nth-child(2)": {
          width: 1,
        },
        "&:nth-child(3)": {
          width: 1,
          whiteSpace: "nowrap" as "nowrap",
        },
        "&:last-child": {
          "& p": {
            fontWeight: 600,
          },
        },
      },
    },
  },
  newTable: {
    marginLeft: "-3px",
    padding: "6px 0",
    width: "100%",
    "& tr": {
      "& td": {
        "& p": {
          margin: 0,
          color: theme.newPalette.text.primary,
          fontSize: 14,
          fontWeight: 400,
          letterSpacing: "0.15px",
          "&.red": {
            color: theme.newPalette.error.main,
          },
          "&.green": {
            color: theme.newPalette.testrtc.primary,
          },
        },
        "&:first-child": {
          width: 75,
          whiteSpace: "nowrap" as "nowrap",
        },
        "&:nth-child(2)": {
          width: 1,
          "& p": {
            padding: "0 13px",
          },
        },
        "&:nth-child(3)": {
          width: 1,
          whiteSpace: "nowrap" as "nowrap",
          "& p": {
            paddingRight: "8px",
          },
        },
        "&:last-child": {
          "& p": {
            fontWeight: 700,
            paddingLeft: "8px",
            lineHeight: "20px",
          },
        },
      },
    },
  },
  testHeaderBorder: {
    borderTop: `1px solid ${theme.newPalette.other.divider}`,
  },
  logSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "14px",
    borderBottom: `1px solid ${theme.newPalette.other.divider}`,
  },
  logText: {
    color: theme.newPalette.text.primary,
  },
  logIDSection: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  logIdText: {
    color: theme.newPalette.text.secondary,
  },
  closeBtn: {
    padding: "4px",
    borderRadius: "9999px",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    color: theme.newPalette.action.active,
    "&:hover": {
      background: "rgba(84, 113, 137, 0.04)",
    },
    "&:focus": {
      background: "rgba(84, 113, 137, 0.3)",
    },
    "&:disabled": {
      backgroundColor: "transparent",
    },
  },

  plainMessageHeaderColor: {
    color: theme.newPalette.testrtc.primary,
  },
});

const sanitizeString = (input: string) => {
  // eslint-disable-next-line no-control-regex
  return input.replace(/[\u0000-\u001F\u007F-\u009F]/g, "") // Control chars
    .replace(/\uFFFD/g, "")                                 // Replacement char
    .trim();
};

const makeRow = (keyText: string, value: any, time: any) => {
  const hasHTML = containsHtml(value.toString());

  const valueP = hasHTML ? <p dangerouslySetInnerHTML={{ __html: value.toString() }} /> :
    <p>{sanitizeString(value.toString())}</p>;
  return (
    <tr key={keyText + value.toString() + Math.random() * 100}>
      <td>
        <p style={{ whiteSpace: "nowrap" }}>{`${time ? `[${time}]` : ""}`}</p>
      </td>
      <td>
        <p>{keyText.startsWith("-") ? "" : "-"}</p>
      </td>
      <td>
        <p>{keyText}</p>
      </td>
      <td>{valueP}</td>
    </tr>
  );
};

// function to convert array to objects

const convertArrayToObject = (arr: Array<any>): any => {
  const obj = {};
  arr.forEach(function (a, index) {
    if (isObject(a)) {
      // check if value is object or string
      Object.keys(a).forEach((key) => {
        obj[key] = a[key];
      });
    } else {
      obj[index] = a;
    }
  });
  return obj;
};

const getRows = (object: any, time: any, color: LogColors, inner?: boolean): JSX.Element[] => {
  let result: any[] = [];
  Object.keys(object).forEach((key: string, index: number) => {
    const value = object[key];
    time = index === 0 && !inner ? time : "";

    if (isArray(value)) {
      if (isNaN(Number(key))) {
        const innerObjectHeaderRow = makeRow(`${startCase(key)}:`, "", time);
        result = result.concat(innerObjectHeaderRow);
      }

      const values = convertArrayToObject(value); // get values from array as object
      const innerRows = getRows({ ...values }, time, color, true);
      result = result.concat(innerRows);
      return;
    }

    if (isObject(value)) {
      // If 'key' is Number means 'value' is array element
      const keyIsNumber = !isNaN(Number(key));

      const keyText = keyIsNumber ? `Item [${key}]` : startCase(key);

      const innerObjectHeaderRow = makeRow(keyText, "", time);
      result.push(innerObjectHeaderRow);

      const innerRows = getRows(value, time, color, true);
      result = result.concat(innerRows);
      return;
    }

    if (value !== undefined && value !== null) {
      const keyText = !inner
        ? `${startCase(key)}:`
        : inner && !isNaN(Number(key))
          ? `${startCase(key)}:`
          : inner
            ? `- ${startCase(key)}:`
            : `[${index + 1}] ${startCase(key)}:`;
      const row = makeRow(keyText, value, time);
      result.push(row);
    }
  });
  return result;
};

const LogModal = (props: ILogModalProps & IDispatch) => {
  const { classes, logs, theme, anchor, config, testResult } = props;

  const isMac = testResult?.hardwareAvailability?.CPU?.modelName.toLowerCase().includes("apple");

  const filteredLogs = logs?.map((log) => {
    if (Array.isArray(log.message)) {
      return {
        color: log.color,
        time: log.time,
        message: log.message.filter((msg: any) => !(isMac && msg.id === 10200)),
      };
    }
    return log;
  }) as ILog[];

  const refs = filteredLogs?.reduce((acc: any, value: any) => {
    acc[value.message] = React.createRef();
    return acc;
  }, {});

  const handleClick = (message: any) => {
    if (refs[message].current) {
      refs[message].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  React.useEffect(() => {
    if (anchor && logs && logs?.length > 0) {
      const testHeader = logs?.find((x) => typeof x.message === "string" && x.message.indexOf(anchor) !== -1)?.message;

      if (testHeader) {
        setTimeout(() => {
          handleClick(testHeader);
        }, 100);
      }
    }
  }, [anchor, handleClick, logs]);

  const plainMessage = (idx: number, message: string, time: any, color: LogColors) => {
    if (!message) {
      return null;
    }

    const messageText = typeof message === "string" ? message : (message as Error).message;

    // e.g. === Location ===
    const isTestHeader = messageText.includes("===");
    const isTestFooter = messageText.includes("End ===");

    if (config?.useNewWidgetDesign) {
      if (containsHtml(messageText)) {
        return (
          <Typography
            key={idx}
            variant="body2"
            className={`${classes.newPlainMessage} ${isTestFooter ? "black" : isTestHeader ? "green" : color}`}
            style={{ padding: "8px 0" }}
            dangerouslySetInnerHTML={{
              __html: ` [${time}]${isTestHeader ? " " : "  -  "}${messageText}`,
            }}
          ></Typography>
        );
      }
      return (
        <div key={idx} ref={refs[messageText]}>
          <div
            className={`${classes.plainTextContainer} ${isTestHeader ? classes.testHeaderBorder : ""}`}
            style={{
              borderTopWidth: isTestFooter ? "0px" : "",
            }}
          >
            <Typography
              variant="body2"
              className={`${classes.newPlainMessage} ${isTestFooter ? color : isTestHeader ? "green" : color}`}
            >{`[${time}]`}</Typography>
            <Typography variant="body2"
                        className={`${classes.newPlainMessage} ${isTestFooter ? color : isTestHeader ? "green" : color}`}>
              {isTestHeader ? " " : "-"}
            </Typography>
            <Typography variant="body2"
                        className={`${classes.newPlainMessage} ${isTestFooter ? color : isTestHeader ? "green" : color}`}>
              {messageText}
            </Typography>
          </div>
        </div>
      );
    }

    if (containsHtml(messageText)) {
      return (
        <Typography
          key={idx}
          className={`${classes.plainMessage} ${classes[`plainMessage_${theme}`]} ${isTestFooter ? "black" : isTestHeader ? "green" : color}`}
          dangerouslySetInnerHTML={{
            __html: ` [${time}]${isTestHeader ? " " : "  -  "}${messageText}`,
          }}
        ></Typography>
      );
    }

    return (
      <div key={idx} ref={refs[messageText]}>
        <Typography
          key={idx}
          className={`${classes.plainMessage} ${classes[`plainMessage_${theme}`]} ${isTestFooter ? "black" : isTestHeader ? "green" : color}`}
        >
          {` [${time}]${isTestHeader ? " " : "  -  "}${messageText}`}
        </Typography>
      </div>
    );
  };

  const complexMessage = (idx: number, message: string, time: any, color: LogColors) => {
    const rows = getRows(message, time, color);
    return (
      <table key={idx} className={config?.useNewWidgetDesign ? classes.newTable : classes.table}>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  if (config?.useNewWidgetDesign) {
    return (
      <Modal
        open={props.open}
        onClose={props.setOpen}
        BackdropProps={{
          style: { background: "rgba(36, 53, 68, 0.5)" },
        }}
      >
        <div className={`${classes.newPaper} logModalMain`}>
          <div className={classes.logContainer}>
            <div className={classes.logSection}>
              <div className={classes.logIDSection}>
                <Typography variant="h3" className={classes.logText}>
                  Log
                </Typography>
                {props.uuid && (
                  <Typography variant="subtitle2" className={classes.logIdText}>
                    Test run id: {props.uuid}
                    {<strong>{props.isQrtcSdkResult ? " - Automated via qualityRTC-SDK" : ""}</strong>}
                  </Typography>
                )}
              </div>
              <button className={classes.closeBtn} onClick={props.setOpen}>
                <GrayCloseIcon />
              </button>
            </div>
            <div style={{ marginBottom: "12px" }} />
            {
              <Scrollbars className={classes.content} style={{ height: "90%" }}>
                {filteredLogs?.map((log: ILog, i: number) => {
                  if (isObject(log.message)) {
                    return complexMessage(i, log.message, log.time, log.color);
                  }
                  return plainMessage(i, log.message, log.time, log.color);
                })}
              </Scrollbars>
            }
          </div>
        </div>
      </Modal>
    );
  }
  return (
    <Modal open={props.open} onClose={props.setOpen}>
      <div className={`${classes.paper} logModalMain`}>
        <span className={classes.close} onClick={props.setOpen}>
          Close
        </span>
        <Typography variant="h6" className={`${classes.title} ${classes[`title_${theme}`]}`}>
          LOG
        </Typography>
        {props.uuid && (
          <p>
            Test run id: {<strong>{props.uuid}</strong>}
            {<strong>{props.isQrtcSdkResult ? " - Automated via qualityRTC-SDK" : ""}</strong>}
          </p>
        )}
        {
          <Scrollbars className={classes.content} style={{ height: 480 }}>
            {logs?.map((log: ILog, i: number) => {
              if (isObject(log.message)) {
                return complexMessage(i, log.message, log.time, log.color);
              }
              return plainMessage(i, log.message, log.time, log.color);
            })}
          </Scrollbars>
        }
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(LogModal));
