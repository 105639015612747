import React from "react";
import TestHeader from "../TestHeader";
import { TurnConnectivityIcon } from "../../icons";
import { useTranslation } from "react-i18next";

interface PlaceholderTestProps {
  title: string;
}

const PlaceholderTest = (props: PlaceholderTestProps) => {
  const { title } = props;
  const { t } = useTranslation(["common", "tests"]);
  return (
    <TestHeader icon={<TurnConnectivityIcon />} title={title} tooltip={t("tests:turnConnectivity.tooltip")}>
      <></>
    </TestHeader>
  );
};

export default PlaceholderTest;
