import React from "react";

import { ICallQualityColors, IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";

import Combined from "./Combined";
import Default from "./Default";
import Detailed from "./Detailed";
import NiceInContact from "./NiceInContact";
import NewDetailed from "../NewTests/NewCallQualityTest/NewDetailed";
import NewCombined from "../NewTests/NewCallQualityTest/NewCombined";
import NewDefault from "../NewTests/NewCallQualityTest/NewDefault";

export interface ICommonCallQulaityTestProps {
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  mainColor?: string;
  config?: TestConfiguration;
  currentThresholds?: ICallQualityColors | null;
  useNewWidgetDesign?: boolean;
}

interface ICallQualityTestProps extends ICommonCallQulaityTestProps {
  testResult?: any;
  colorThresholds?: IColorThresholds;
}

type WidgetVariant = "inbound" | "combined" | "detailed";

const initialValues = {
  avgJit: undefined,
  avgPl: undefined,
  avgOutPl: undefined,
  avgRtt: undefined,
  mark: undefined,
  avgJit2: undefined,
  avgPl2: undefined,
  avgRtt2: undefined,
  mark2: undefined,
  remoteAddress: undefined,
  remoteAddress2: undefined,
};

const CallQualityTestWrapper = (props: ICallQualityTestProps) => {
  const { testResult, colorThresholds, useNewWidgetDesign, config } = props;

  let testCall: any = initialValues;
  if (testResult) {
    if (testResult?.testCallG711) {
      testCall = testResult.testCallG711;
    } else if (testResult?.testCallOpus) {
      testCall = testResult?.testCallOpus;
    } else {
      testCall = testResult?.testCall || initialValues;
    }
  }
  const currentThresholds = colorThresholds ? colorThresholds.CallQuality : null;
  const niceInContact = (props.config as any).CallQuality?.ConnectionInfoName === "nice";
  const variant: WidgetVariant = (props.config as any).CallQuality?.widgetVariant || "inbound";

  if (niceInContact) {
    return <NiceInContact {...props} testCall={testCall} currentThresholds={currentThresholds} />;
  } else {
    switch (variant) {
      case "inbound":
        return <Combined {...props} testCall={testCall} currentThresholds={currentThresholds} />;

      case "combined":
        return <Combined {...props} testCall={testCall} currentThresholds={currentThresholds} />;

      case "detailed":
        return <Detailed {...props} testCall={testCall} currentThresholds={currentThresholds} config={config} />;

      default:
        return <Combined {...props} testCall={testCall} currentThresholds={currentThresholds} />;
    }
  }
};

export default CallQualityTestWrapper;
