import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";

import TestHeader from "../../TestHeader";
import { CallQualityIco } from "../../../icons";
import { ICommonCallQulaityTestProps } from ".";
import { RenderValues } from "../../helpers/utils";
import { formatNumber, getColorValue } from "../../../../helpers/utils";
import { withStyles } from "@material-ui/core";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import DonutChart from "../NewTests/UI/DonutChart";
import ResultRow from "../UI/ResultRow";

interface IDefaultProps extends ICommonCallQulaityTestProps {
  testCall?: any;
  theme?: any;
  classes?: any;
  config?: TestConfiguration;
}

const styles = (theme: any): any => ({
  testContainer: {
    display: "flex",
    flex: 1,
    gap: "6px",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 16px 16px 10px",
  },
  svgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  resultContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
});

const Default = (props: IDefaultProps) => {
  const { theme, testCall, config, mainColor, currentThresholds, classes } = props;
  const { avgJit, avgJitOut, avgPl, avgOutPl, avgRtt, mark, error, meta, mosInbound, transportProtocol } = testCall;
  const codec = testCall.codec || (config as any).CallQuality?.codec;

  const colorTypes = {
    errorColor: config?.useNewWidgetDesign ? theme.newPalette.error.main : theme.customColors.errorColor,
    warningColor: config?.useNewWidgetDesign ? theme.newPalette.warning.main : theme.customColors.warningColor,
    successColor: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
  };

  const defaultProps = {
    mainColor,
    color: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
    formatting: formatNumber,
  };

  // mos calculation is not twilio so no mos2 value
  // so there is an mosInbound and mosOutbound(mark/mos1)
  // this is combined mode so average mosInbound and mosOutbound(mark/mos1)

  const mosCombined = mark && mosInbound ? (mark + mosInbound) / 2 : mark ? mark : mosInbound;

  // const overall =
  //   mark > 4 ? "Excellent" : mark > 3 ? "Good" : mark > 2 ? "Fair" : mark > 1 ? "Poor" : "Bad";
  // const mosValue = mark
  //   ? `${mark.toFixed(1)} <span className="result-extra">(${overall})</span>`
  //   : "No Data";

  const overall = mosCombined > 4 ? "Excellent" : mosCombined > 3 ? "Good" : mosCombined > 2 ? "Fair" : mosCombined > 1 ? "Poor" : "Bad";
  const mosValue = mosCombined ? `${mosCombined.toFixed(1)} <span className="result-extra">(${overall})</span>` : "No Data";
  const packetLoss = avgPl > avgOutPl ? avgPl : avgOutPl;
  const jitter = avgJit > avgJitOut ? avgJit : avgJitOut;

  const titleCodec = codec === "G711" ? "G.711" : "";

  const calculateMos = (value: any) => {
    return value && value !== "N/A" ? value.toFixed(1) : "N/A";
  };

  const { t } = useTranslation(["common", "tests"]);

  const title = config?.CallQuality?.title
    ? config?.CallQuality.title
    : t("tests:call-quality.title", { codec: titleCodec ? " - " + titleCodec : "" });

  return (
    <TestHeader
      icon={<CallQualityIco />}
      title={title}
      tooltip={t("tests:call-quality.tooltip")}
      error={error}
      knowledgeUrl={props.knowledgeUrl}
      estimateTime={props.estimateTime}
      testName={props.testName}
      errorsCount={meta?.errorsCount}
      badge={transportProtocol}
    >
      {config?.useNewWidgetDesign ? (
        <div className={classes.testContainer}>
          <div className={classes.svgContainer}>
            <DonutChart
              value={calculateMos(mosCombined)}
              status={overall}
              parentId="CQC-r-MOSScore"
              childId="CQC-c-mosValue"
              textColor={
                currentThresholds && currentThresholds.mark
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: mosCombined,
                        warningValue: currentThresholds.mark.warning,
                        errorValue: currentThresholds.mark.error,
                      },
                      currentThresholds.mark.isLess
                    )
                  : defaultProps.color
              }
            />
          </div>
          <div className={classes.resultContainer}>
            <ResultRow
              label={t("Round Trip")}
              value={avgRtt}
              unit="ms"
              color={
                currentThresholds && currentThresholds.avgRtt
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: avgRtt,
                        warningValue: currentThresholds.avgRtt.warning,
                        errorValue: currentThresholds.avgRtt.error,
                      },
                      currentThresholds.avgRtt.isLess
                    )
                  : defaultProps.color
              }
              id="CQC-r-roundTrip"
              valueId="CQC-c-avgRtt"
            />
            <ResultRow
              label={t("Packet Loss")}
              value={packetLoss}
              formatting={true}
              unit="%"
              color={
                currentThresholds && currentThresholds.avgPl
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: packetLoss,
                        warningValue: currentThresholds.avgPl.warning,
                        errorValue: currentThresholds.avgPl.error,
                      },
                      currentThresholds.avgPl.isLess
                    )
                  : defaultProps.color
              }
              id="CQC-r-packetLoss"
              valueId="CQC-c-avgPl"
            />
            <ResultRow
              label={t("jitter")}
              value={jitter}
              unit="ms"
              color={
                currentThresholds && currentThresholds.avgJit
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: jitter,
                        warningValue: currentThresholds.avgJit.warning,
                        errorValue: currentThresholds.avgJit.error,
                      },
                      currentThresholds.avgJit.isLess
                    )
                  : defaultProps.color
              }
              id="CQC-r-jitter"
              valueId="CQC-c-avgJit"
            />
          </div>
        </div>
      ) : (
        <div className={`tests-card-content tst-call-quality`}>
          <table>
            <tbody>
              <tr className="tst-mos" id="CQC-r-MOSScore">
                <td>{t("MOS Score")}</td>
                <td id="CQC-c-mosValue">
                  <RenderValues
                    {...defaultProps}
                    value={mosValue}
                    color={
                      currentThresholds && currentThresholds.mark
                        ? getColorValue(
                            {
                              ...colorTypes,
                              // value: mark,
                              value: mosCombined,
                              warningValue: currentThresholds.mark.warning,
                              errorValue: currentThresholds.mark.error,
                            },
                            currentThresholds.mark.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
              <tr className="tst-round-trip" id="CQC-r-roundTrip">
                <td>{t("Round Trip")}</td>
                <td id="CQC-c-avgRtt">
                  <RenderValues
                    {...defaultProps}
                    formatting={(value: number) => `${value} <span>ms</span>`}
                    value={avgRtt}
                    color={
                      currentThresholds && currentThresholds.avgRtt
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgRtt,
                              warningValue: currentThresholds.avgRtt.warning,
                              errorValue: currentThresholds.avgRtt.error,
                            },
                            currentThresholds.avgRtt.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
              <tr className="tst-packet-loss" id="CQC-r-packetLoss">
                <td>{t("Packet Loss")}</td>
                <td id="CQC-c-avgPl">
                  <RenderValues
                    {...defaultProps}
                    formatting={(value: number) => `${value.toFixed(1)}%`}
                    value={packetLoss}
                    color={
                      currentThresholds && currentThresholds.avgPl
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: packetLoss,
                              warningValue: currentThresholds.avgPl.warning,
                              errorValue: currentThresholds.avgPl.error,
                            },
                            currentThresholds.avgPl.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
              <tr className="tst-jitter" id="CQC-r-jitter">
                <td>{t("jitter")}</td>
                <td id="CQC-c-avgJit">
                  <div className="value-row-pair">
                    <RenderValues
                      value={jitter}
                      {...defaultProps}
                      color={
                        currentThresholds && currentThresholds.avgJit
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: jitter,
                                warningValue: currentThresholds.avgJit.warning,
                                errorValue: currentThresholds.avgJit.error,
                              },
                              currentThresholds.avgJit.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(Default));
