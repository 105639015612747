import axios from "axios";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { IReportOptions } from "../interfaces";
import { file2Base64, getParameters, IsLocalhost, sanitizeData } from "../helpers/utils";
import { ScoreSelection, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { IAPITestResultData, ITestsResultToServer } from "twillio-tests/core/TestResults";
import { sha256 } from "crypto-hash";
import html2pdf from "html2pdf.js";
import { delay } from "lodash";

const dev = false;

export default class DocumentService {
  static async getLayout() {
    const uuid: any = window.location.pathname.replace("/", "");
    let options = getParameters();
    const report = options.report;

    let currentUuid = uuid;
    const viewMode = !!uuid;

    try {
      const netlifyBaseURL = IsLocalhost() ? "http://localhost:9000" : "";
      const answer = await axios.get(
        `${netlifyBaseURL}/.netlify/functions/get-config?config=${options.config || ""}&region=${options.region || null}&host=${options.host || null}`
      );
      let data = answer.data.config;
      if (typeof answer.data === "string") {
        // probably invalid config JSON file, so we received as string and not as json, this will fail
        data = JSON.parse(answer.data);
      }
      let config: TestConfiguration = data as TestConfiguration;
      config.debug = false;
      // config.location = answer.data.location;
      if (options.run && options.run?.split(",").length > 0) {
        config.testsList = options.run.split(",");
      }

      if (config.inviteAccessOnly && (!options.invite || options.invite === "") && !uuid && !IsLocalhost()) {
        throw new Error("There is no invite token provided, please provide invite token in url.");
      }

      let serverResults = null;
      try {
        if (uuid) {
          // const headerKey = dev ? "x-consumer-username" : "apikey";
          // const result = await axios.get(`${PATH}/${uuid}`, {
          //   headers: { [`${headerKey}`]: API_KEY },
          // });
          const result = await axios.get(
            `${netlifyBaseURL}/.netlify/functions/get-result?customer=${config.customerName || ""}&uuid=${uuid || null}`
          );
          serverResults = {
            ...sanitizeData(result.data.data),
            scoringResult: result.data.scoringResult,
            rulesEngineResult: result.data.rulesEngineResult,
            createDate: result.data.createDate,
          };
          if (options.access) {
            const sha256UUID = await sha256(uuid);
            const sha256access = options.access;
            if (sha256access === sha256UUID) {
              config.logRestrictAccess = false;
              config.isClientPortal = false;
              config.silent = false;
            }
          }
        }
      } catch (err) {
        console.error(err.stack);
        currentUuid = null;
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      try {
        if (options.invite) {
          const result = await axios.get(
            `${netlifyBaseURL}/.netlify/functions/networktest-invite?customer=${config.customerName || ""}&invite=${options.invite || null}`
          );

          const objInviteData = result.data;
          if (objInviteData.options && objInviteData.options.length > 0) {
            const objOptions = JSON.parse(objInviteData.options);
            config = {
              ...config,
              fields: (config.fields || []).concat(objOptions.fields || []),
            };
            options = { ...options, ...objOptions.queryParams };
          }
          if (objInviteData.fields) {
            config.inviteFields = { ...objInviteData.fields, title: objInviteData?.fields?.reason };
          }
        }
      } catch (err) {
        console.error(err);
        config.invalidInvite = true;
        config.invalidInviteMessage = "This link is no longer valid. It has expired.";
        // throw new Error("Invalid invite token provided, please check invite token.");
      }

      if (!config.header_url || !config.footer_url) {
        console.error("Invalid config data, missing, header_url or footer_url", {
          data: config,
        });
        throw new Error("Invalid config data, missing, header_url or footer_url");
      }

      if (options.debug && options.debug === "true") {
        console.log("===== Debug Mode =====");
        config.debug = true;
        config.disableAutoSave = true;
        config.fields = [];
        config.emailRequiredForStart = false;
        config.userRequirePassword = false;
        delete config.userAuthKey;
      }
      if (options.debug && options.debug === "test") {
        console.log("===== Test Mode =====");
        config.disableAutoSave = true;
        config.emailRequiredForStart = false;
        config.userRequirePassword = false;

        delete config.userAuthKey;
      }
      if (options.region) {
        console.log("===== Region: " + options.region + " =====");
        config.region = options.region;
      }
      if (options.save) {
        console.log("===== Save to new API key: " + options.save + " =====");
        config.API_KEY = options.save;
        config.API_URL = "https://api.testrtc.com/v1st/networktest"; // force staging
        config.disableAutoSave = false;
      }

      if (options.verbose) {
        config.verbose = options.verbose || "all";
      }
      const [headerData, footerData] = await Promise.all([axios.get(config.header_url), axios.get(config.footer_url)]);
      const backgroundColor = config.backgroundColor;
      const explanationMessageColor = config.explanationMessageColor;
      const hidePoweredByRTC = config.hidePoweredByRTC;
      const emailRequiredForStart = config.emailRequiredForStart;
      const reasonFieldValidation = config.reasonFieldValidation;
      const useTextAsEmail = config.useTextAsEmail;
      const fields = config.fields;
      if (serverResults?.testResult?.mediaIp?.ip) {
        if (serverResults?.testResult?.location?.ip !== serverResults?.testResult?.mediaIp?.ip) {
          if (config && serverResults?.testResult?.location?.ip) {
            if (config.location) {
              config.location.widgetType = "detailed";
            } else {
              config.location = {
                widgetType: "detailed",
              } as any;
            }
          }
        }
      }

      if (options.embedded === "true") {
        // tslint:disable-next-line:only-arrow-functions
        window.onkeydown = function (event: KeyboardEvent) {
          if (event.code === "KeyU" && event.ctrlKey) {
            event.preventDefault();
          }
        };
      }

      const res = {
        serverResults,
        header: options.embedded !== "true" ? headerData.data : "",
        footer: options.embedded !== "true" ? footerData.data : "",
        config: { ...config, options },
        fields,
        uuid: currentUuid,
        viewMode,
        report,
        backgroundColor,
        explanationMessageColor,
        hidePoweredByRTC,
        emailRequiredForStart,
        useTextAsEmail,
        reasonFieldValidation,
      };
      console.log("Test Configuration", { res });
      return res;
    } catch (err) {
      console.error(err.stack);
      throw new Error(err);
    }
  }

  static sendReport(fieldOption: IReportOptions, uuid: any, config: TestConfiguration) {
    console.log("sendReport", { fieldOption });
    const PATH = config.API_URL;
    const API_KEY = config.API_KEY;
    const headerKey = dev ? "x-consumer-username" : "apikey";
    return axios.put(`${PATH}/${uuid}`, fieldOption, {
      headers: { [`${headerKey}`]: API_KEY },
    });
  }

  static async getPdf(fileName: string) {
    return new Promise((resolve: (value: string) => void) => {
      delay(async () => {
        const element = document.getElementsByClassName("pdfReport")[0];
        const opt = {
          pagebreak: { avoid: "img" },
          margin: [14, 0, 20, 0],
          filename: fileName,
          html2canvas: { windowWidth: 1000 },
          jsPDF: { orientation: "p", unit: "pt", format: "letter" },
        };
        // New Promise-based usage:
        const file = await html2pdf()
          .from(element)
          .set(opt)
          .outputPdf()
          .then((pdfFile: string) => pdfFile);
        resolve(btoa(file));
      }, 2000);
    });
  }

  static async getFileName(fileNameParams: any) {
    let nameFormat = "ID-YYYYMMDD-EMAIL-REASON"
      .replace("ID", fileNameParams.testRunId)
      .replace("YYYYMMDD", format(parseISO(new Date().toISOString()), "yyyyMMdd"))
      .replace("EMAIL", fileNameParams.email)
      .replace("REASON", fileNameParams.title)
      .replace(/\s/g, "-");
    if (nameFormat.length <= 250) {
      return `${nameFormat}.pdf`;
    } else {
      nameFormat = nameFormat.substring(0, 250);
      return `${nameFormat}.pdf`;
    }
  }

  static async sendResult(testRunId: string, data: ITestsResultToServer, config: TestConfiguration) {
    try {
      const recordingEnabled = config?.CallQuality?.analyseRecording || config?.options?.analyseRecording === "true" || false;

      data.testResult.configName = config?.configName;
      data.testResult.scoreSelection = config?.scoreSelection || ScoreSelection.default;

      let fileName = "network-test-highlights.pdf";
      if (config.theme === "livevox") {
        fileName = await this.getFileName({
          testRunId,
          email: data.email,
          title: data.title,
        });
      }

      const apiData: IAPITestResultData = {
        test: data,
        testId: testRunId,
      };

      if (config.options.pii) {
        apiData.pii = config.options.pii;
      }

      const file = config.emailAlertPdf ? await this.getPdf(fileName) : null;
      if (file) {
        apiData.file = file;
        apiData.fileName = fileName;
      }

      if (recordingEnabled) {
        const outgoingAudio = data.testResult.testCall?.outgoingAudio;
        const incomingAudio = data.testResult.testCall?.incomingAudio;

        const downloadFile = (url: string, outgoing: boolean = true) => {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", outgoing ? "outgoing.wav" : "incoming.wav");
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        };

        try {
          if (outgoingAudio) {
            downloadFile(outgoingAudio, true);
            const outgoingBlob = await fetch(outgoingAudio)
              .then((r: any) => r.blob())
              .catch((_e: any) => false);
            if (outgoingBlob) {
              const _tempFile = new File([outgoingBlob], `outgoingAudio-${testRunId}.wav`, { type: "audio/wav;codecs=opus" });
              const _res = await file2Base64(_tempFile);
              if (_res) {
                console.debug({ URL: outgoingAudio, outgoingAudioBase64: _res });
                // apiData.outgoingFile = _res;
              } else {
                console.warn("Failed to create file from outgoing recording!");
              }
            }
          }
          if (incomingAudio) {
            downloadFile(incomingAudio, false);
            const incomingBlob = await fetch(incomingAudio)
              .then((r: any) => r.blob())
              .catch((_e: any) => false);
            if (incomingBlob) {
              const _tempFile = new File([incomingBlob], `incomingAudio-${testRunId}.wav`, { type: "audio/wav;codecs=opus" });
              const _res = await file2Base64(_tempFile);
              if (_res) {
                console.debug({ URL: incomingAudio, incomingAudioBase64: _res });
                // apiData.incomingFile = _res;
              } else {
                console.warn("Failed to create file from incoming recording!");
              }
            }
          }
        } catch (e: any) {
          console.warn("Failed to attach recording files to request", { error: e });
        }
      }

      console.log("Saving Results", {
        customer: config.customerName,
        apiData: { ...apiData, file: undefined, outgoingFile: undefined, incomingFile: undefined },
      });

      const netlifyBaseURL = IsLocalhost() ? "http://localhost:9000" : "";

      return await axios.post(`${netlifyBaseURL}/.netlify/functions/save-result`, JSON.stringify({ customer: config.customerName, apiData }));
    } catch (err) {
      console.error("sendResult", err.message, { err });
      throw new Error(err);
    }
  }

  static async sendEmailPdf(fields: any, config: TestConfiguration, uuid: string) {
    let PATH = config.REPORT_API_URL;
    if (!PATH) {
      throw new Error("Missing config API_URL");
    }
    if (!uuid) {
      throw new Error("Missing UUID.");
    }
    PATH = PATH.replace("{id}", uuid);

    try {
      let fileName = `network-test-highlights-${uuid}.pdf`;
      if (config.theme === "livevox") {
        fileName = await this.getFileName({
          testRunId: uuid,
          email: fields.email,
          title: fields.title,
        });
      }

      const file = await this.getPdf(fileName);
      const netlifyBaseURL = IsLocalhost() ? "http://localhost:9000" : "";

      await axios.post(
        `${netlifyBaseURL}/.netlify/functions/send-email`,
        JSON.stringify({
          apiUrl: PATH,
          customer: config.customerName,
          apiData: { email: fields.email, file, fileName },
        })
      );

      return true;
    } catch (err) {
      console.error("sendResult", err.message, { err });
      throw new Error(err);
    }
  }

  static async downloadReport(uuid: string, config: TestConfiguration) {
    try {
      const PATH = config.CredentialsApiUrl;
      const result = await axios.get(`${PATH}/convertToPdfApiToken`);
      const token = result.data.token;

      const filename = `network-test-${uuid}`;
      const url =
        `https://v2.convertapi.com/convert/web/to/pdf?Token=${token.Id}&download=attachment&ConversionDelay=8&filename=${filename}&url=` +
        encodeURI(`${window.location.origin}/${uuid}?report=pdf`);

      window.open(url, "_blank");

      return;
    } catch (err: any) {
      console.error(`Could not download report. Error: ${err.message || "unknown"}`);
      alert("Could not download report. Contact support");
    }
  }
}
