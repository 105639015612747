import { useEffect, useMemo, useState } from "react";

// export const extensionId = "nkplohehikpnkcipffofnbjdhljnfink";
export const extensionId = "ninnkfgcogbifinffmalccnoijlhhnok";

const useExtensionTracker = (from?: string) => {
  const [isInstalled, setIsInstalled] = useState(false);

  const checkAndSetStatus = () => {
    if (chrome && chrome.runtime) {
      // Send a message to the extension to check if it's installed
      chrome.runtime.sendMessage(
        extensionId,
        {
          action: "GET_EXTENSION_INFO",
        },
        (response) => {
          if (!chrome.runtime.lastError) {
            console.log(response);
            setIsInstalled(true);
          } else {
            setIsInstalled(false);
          }
        }
      );
    } else {
      setIsInstalled(false);
    }
  };


  useEffect(() => {
    if (!installed) {
      checkAndSetStatus();
    }
  }, []);

  const installed = useMemo(() => {
    return isInstalled;
  }, [from, isInstalled])

  return installed;
};

export default useExtensionTracker;
